import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import VueCoreVideoPlayer from 'vue-core-video-player'

import VueVideoPlayer from 'vue-video-player'

// 引入样式
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

// 使用组件
Vue.use(VueVideoPlayer)

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  const VConsole = require('vconsole')
  // eslint-disable-next-line
  const my_console = new VConsole()
}

Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})


new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
