import Vue from 'vue'
import Router from 'vue-router'


const routes = [
    {
        path: '/practise',
        name: 'practise',
        component: () => import('@/view/practise'),
    },
    {
        path: '/error-list',
        name: 'errorList',
        component: () => import('@/view/errorList'),
    },
    {
        path: '/topic-single',
        name: 'topic',
        component: () => import('@/view/topic/topic'),
    },
    {
        path: '/judge',
        name: 'judge',
        component: () => import('@/view/judge'),
    },
    {
        path: '/pdfRead',
        name: 'pdfRead',
        component: () => import('@/view/pdfRead'),
    },
    {
        path: '/demo',
        name: 'Demo',
        component: () => import('@/view/demo'),
    },
    {
        path: '/course-mini-video',
        name: 'MiniVideoCourse',
        component: () => import('@/view/course')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/view/register'),
    },
    {
        path: '/zw-register-list',
        name: 'registerList',
        component: () => import('@/view/register/list'),
    }
]
const router = new Router({
    routes: routes
})
Vue.use(Router)
export default router
